import type { Alarm, AlarmHistory } from '@device-api/alarm/model';
import type { DataResponse } from '@device-api/data.v2/model';
import type { Operation, Status as OperationStatus } from '@device-api/coreOperation/model';
import type { DeviceBase } from '@device-api/device/model';
import type { DeviceFilter, SelectedFilters } from '@device-api/filter/model';
import type { Group, EditGroup, CreateGroup } from '@device-api/groups/model';
import type { DeviceOptions } from '@device-api/options/model';
import type { AlarmActionListEntry, GetAlarmAction, EditAlarmAction } from '@device-api/alarmAction/model';
import type { ChannelResponse, ChannelsResponse, IOPorts } from '@device-api/inputs/model';
import type { AlarmStatus } from '@device-api/alarm/model';
import type { InputType, GetInputResponse } from '@device-api/inputs/model';
import type { DeviceSettings } from '@device-api/settings/model';
import type { Radar } from '@device-api/radar/model';
import type { GetAllMarksResult as MarkersResponse, Marker } from '@device-api/marker/model';
import type { EditSchedule, ScheduleEntry, ScheduleListEntry } from '@device-api/schedule/model';
import type { FormulaEntry } from '@device-api/formula/model';
import type { DeviceTypeProperties } from '@device-api/properties/model';
import useApiClient, { useRequestInit } from './apiClient';

export function useDeviceApi(
  baseUrl: string,
  ssr?: boolean,
  sessionCookie: string | null | undefined = null
) {
  const apiClient = useApiClient(ssr, true);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const getData = async (id: Array<string>, options?: { records?: number, start?: Date; end?: Date }) => {
    options?.start?.setUTCHours(0, 0, 0);
    options?.end?.setUTCHours(23, 59, 59);

    const params = options
      ? new URLSearchParams({
        ...(options.start ? { start: options.start.toISOString() } : {}),
        ...(options.end ? { end: options.end.toISOString() } : {}),
        ...(options.records ? { records: String(options.records) } : {})
      }).toString()
      : undefined;

    return await apiClient<DataResponse>(
      `${baseUrl}/api/v2/devices/${id}/data${params ? `?${params}` : ''}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );
  };

  const getFormulas = async (deviceId: string) =>
    await apiClient<Array<FormulaEntry>>(
      `${baseUrl}/api/v1/devices/${deviceId}/formulas`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getFormula = async (deviceId: string, formulaId: number) =>
    await apiClient<FormulaEntry>(
      `${baseUrl}/api/v1/devices/${deviceId}/formulas/${formulaId}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editFormula = async (deviceId: string, formulaId: number, data: Record<string, unknown>) =>
    await apiClient<void>(
      `${baseUrl}/api/v1/devices/${deviceId}/formulas/${formulaId}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const createFormula = async (deviceId: string, data: Record<string, unknown>) =>
    await apiClient<void>(
      `${baseUrl}/api/v1/devices/${deviceId}/formulas`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const deleteFormula = async (deviceId: string, formulaId: number) =>
    await apiClient<void>(
      `${baseUrl}/api/v1/devices/${deviceId}/formulas/${formulaId}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getSchedules = async () =>
    await apiClient<Array<ScheduleListEntry>>(
      `${baseUrl}/api/v1/schedules`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getSchedule = async (schedule: number) =>
    await apiClient<ScheduleEntry>(
      `${baseUrl}/api/v1/schedules/${schedule}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editSchedule = async (schedule: number, data: EditSchedule) =>
    await apiClient<{ status: string }>(
      `${baseUrl}/api/v1/schedules/${schedule}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const deleteSchedule = async (schedule: number) =>
    await apiClient<{ status: string }>(
      `${baseUrl}/api/v1/schedules/${schedule}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const createSchedule = async (data: EditSchedule) =>
    await apiClient<{ status: string, value: number }>(
      `${baseUrl}/api/v1/schedules`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const createDevice = async (name: string, type: string) =>
    await apiClient<DeviceBase>(
      `${baseUrl}/api/v1/devices?name=${encodeURIComponent(name)}&type=${encodeURIComponent(type)}`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const deleteDevice = async (id: number) =>
    await apiClient<DeviceBase>(
      `${baseUrl}/api/v1/devices/${id}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const getInput = async (id: string, inputType: InputType, index: number) =>
    await apiClient<GetInputResponse<unknown>>(
      `${baseUrl}/api/v1/devices/${id}/${inputType}/${index}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editInput = async (id: string, inputType: InputType, index: number, data: Record<string, unknown>) =>
    await apiClient<void>(
      `${baseUrl}/api/v1/devices/${id}/${inputType}/${index}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const getAlarmActions = async () =>
    await apiClient<AlarmActionListEntry[]>(
      `${baseUrl}/api/v1/alarm_actions`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getAlarmAction = async (action: number) =>
    await apiClient<GetAlarmAction>(
      `${baseUrl}/api/v1/alarm_actions/${action}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editAlarmAction = async (action: number, data: EditAlarmAction) =>
    await apiClient<{ status: string, value: number }>(
      `${baseUrl}/api/v1/alarm_actions/${action}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const createAlarmAction = async (data: EditAlarmAction) =>
    await apiClient<{ status: string, value: number }>(
      `${baseUrl}/api/v1/alarm_actions`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const deleteAlarmAction = async (action: number) =>
    await apiClient<{ status: string }>(
      `${baseUrl}/api/v1/alarm_actions/${action}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getMarkers = async (deviceId: string, index = 0) => await apiClient<MarkersResponse>(
    `${baseUrl}/api/v1/devices/${deviceId}/channel/${index + 1}/markers`,
    getRequestInit({
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
  );

  const createMarker = async (deviceId: string, index = 0, data: Marker) => await apiClient<Marker>(
    `${baseUrl}/api/v1/devices/${deviceId}/channel/${index + 1}/markers`,
    getRequestInit({
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  );

  const editMarker = async (
    deviceId: string,
    marker: number,
    index = 0,
    data: Marker
  ) => await apiClient<Marker>(
    `${baseUrl}/api/v1/devices/${deviceId}/channel/${index + 1}/markers/${marker}`,
    getRequestInit({
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  );

  const deleteMarker = async (deviceId: string, marker: number, index = 0) => await apiClient<void>(
    `${baseUrl}/api/v1/devices/${deviceId}/channel/${index + 1}/markers/${marker}`,
    getRequestInit({
      method: 'DELETE',
      headers: {
        Accept: 'application/json'
      }
    })
  );

  const getDeviceProperties = async (id: string) =>
    await apiClient<DeviceTypeProperties>(
      `${baseUrl}/api/v1/devices/${id}/properties`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getIOPorts = async (id: string) =>
    await apiClient<IOPorts>(
      `${baseUrl}/api/v1/devices/${id}/io`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getChannel = async (id: string, index: number) =>
    await apiClient<Array<ChannelResponse>>(
      `${baseUrl}/api/v1/devices/${id}/channel/${index}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getChannels = async (id: string) =>
    await apiClient<Array<ChannelsResponse>>(
      `${baseUrl}/api/v1/devices/${id}/channel`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getRelay = async (id: number) =>
    await apiClient<Array<boolean>>(
      `${baseUrl}/api/v1/devices/${id}/relay`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const setRelay = async (id: number, state: Array<boolean>) =>
    await apiClient<void>(
      `${baseUrl}/api/v1/devices/${id}/relay`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify(state),
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getAlarms = async (ids: number[] = []) =>
    await apiClient<Alarm[]>(
      `${baseUrl}/api/v1/alarms`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({ devices: ids }),
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getAlarm = async (id: number) =>
    await apiClient<Alarm>(
      `${baseUrl}/api/v1/alarms/${id}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getAlarmHistory = async (ids: number[] = [], from: Date, to: Date) =>
    await apiClient<AlarmHistory[]>(
      `${baseUrl}/api/v1/alarm-history`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({ devices: ids, from: from.toISOString(), to: to.toISOString() }),
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const setAlarmStatus = async (device: number, id: number, status: AlarmStatus) =>
    await apiClient<{ status: string }>(
      `${baseUrl}/api/v1/devices/${device}/alarms/${id}/status?set=${status}`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getRadar = async (id: string, timestamp: string, channel = 0) =>
    await apiClient<Radar>(
      `${baseUrl}/api/v1/devices/${id}/radar/${channel}?timestamp=${timestamp}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editRadar = async (id: string, channel: number, data: { reference: string }) =>
    await apiClient<Radar>(
      `${baseUrl}/api/v1/devices/${id}/radar/${channel}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify({
          ChannelDefaultRadar: data.reference,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }));

  const getSettings = async (id: number) =>
    await apiClient<DeviceSettings>(
      `${baseUrl}/api/v1/devices/${id}/settings`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editSettings = async (id: string, data: Record<string, unknown>) =>
    await apiClient<DeviceSettings>(
      `${baseUrl}/api/v1/devices/${id}/settings`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );

  const getOptions = async (id: number) =>
    await apiClient<DeviceOptions>(
      `${baseUrl}/api/v1/devices/${id}/options`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getDevices = async (useContext = false, groupIds: Array<number> = [], includeXpm = false) =>
    await apiClient<DeviceBase[]>(
      `${baseUrl}/api/v1/devices?use_context=${useContext}${groupIds.length ? `&groups=${groupIds.join(',')}` : '' }&include_xpm=${includeXpm}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getDeviceFilter = async (useContext: boolean, filterOptions: SelectedFilters) =>
    await apiClient<{ devices: DeviceBase[]; filter: DeviceFilter }>(
      `${baseUrl}/api/v1/deviceFilter${useContext ? '?use_context=true' : ''}`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({
          ...filterOptions,
          includeXpm: true
        }),
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getGroup = async (id: number) =>
    await apiClient<Group>(
      `${baseUrl}/api/v1/groups/${id}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getGroups = async (useContext = true, organization?: string) =>
    await apiClient<Group[]>(
      `${baseUrl}/api/v1/groups?${useContext ? 'use_context=true' : `organization=${organization}`}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const editGroup = async (group: EditGroup, useContext = true, organization?: string) =>
    await apiClient<Group[]>(
      `${baseUrl}/api/v1/groups?${useContext ? 'use_context=true' : `organization=${organization}`}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(group),
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const createGroup = async (group: CreateGroup, useContext = true, organization?: string) =>
    await apiClient<Group[]>(
      `${baseUrl}/api/v1/groups?${useContext ? 'use_context=true' : `organization=${organization}`}`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify(group),
        headers: {
          Accept: 'application/json',
        },
      })
    );
  
  const deleteGroup = async (groupId: number, useContext = true, organization?: string) =>
    await apiClient(
      `${baseUrl}/api/v1/groups/${groupId}?${useContext ? 'use_context=true' : `organization=${organization}`}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const triggerOperation = async (deviceId: string, operation: Operation) => await apiClient<number>(
    `${baseUrl}/api/v1/devices/${deviceId}/operation?operation=${operation}`,
    getRequestInit({
      method: 'POST',
      headers: {
        Accept: 'application/json'
      }
    })
  );

  const pollOperationStatus = async (
    deviceId: string,
    sequence: number,
    operation: Operation
  ) => await apiClient<OperationStatus>(
    `${baseUrl}/api/v1/devices/${deviceId}/operation?sequence=${sequence}&operation=${operation}`,
    getRequestInit({
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
  );

  const updateGroupId = async (groupId: number, devices: number[]) =>
    await apiClient(
      `${baseUrl}/api/v1/devices/updateGroup`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify({ groupId, devices }),
        headers: {
          Accept: 'application/json',
        }
      })
    );

  return {
    getIOPorts,
    getDeviceProperties,
    getSchedules,
    getSchedule,
    editSchedule,
    deleteSchedule,
    createSchedule,
    getFormula,
    getFormulas,
    editFormula,
    createFormula,
    deleteFormula,
    getInput,
    editInput,
    editSettings,
    getAlarm,
    getAlarms,
    getAlarmActions,
    getAlarmAction,
    editAlarmAction,
    deleteAlarmAction,
    createAlarmAction,
    setAlarmStatus,
    getMarkers,
    createMarker,
    editMarker,
    deleteMarker,
    getDevices,
    createDevice,
    deleteDevice,
    getRelay,
    setRelay,
    getData,
    getRadar,
    editRadar,
    getGroups,
    getGroup,
    getOptions,
    getSettings,
    getDeviceFilter,
    editGroup,
    getChannel,
    getChannels,
    createGroup,
    deleteGroup,
    updateGroupId,
    triggerOperation,
    pollOperationStatus,
    getAlarmHistory
  };
}
