<script setup lang="ts">
import { cn } from '../lib/utils';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../components/alert-dialog';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  message: {
    type: String,
    required: false,
    default: ''
  },
});

const emit = defineEmits<{
  (e: 'confirm', arg: boolean): void;
}>();
</script>

<template>
  <AlertDialog :open="props.show">
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle class="text-gray-300">
          {{ props.title }}
        </AlertDialogTitle>
        <AlertDialogDescription class="my-2 text-md">
          <span class="text-gray-400">
            {{ props.message }}
          </span>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <div class="flex flex-row justify-between w-full">
          <AlertDialogCancel
            :class="cn('dark:text-white border border-gray-600 px-6 dark:hover:bg-gray-600 text-md')"
            @click="() => emit('confirm', false)"
          >
            <span class="text-gray-300">{{ $t('common.cancel') }}</span>
          </AlertDialogCancel>
          <AlertDialogAction
            :class="cn('dark:text-white border border-gray-600 px-6 bg-dark-widget dark:hover:bg-gray-600 text-md')"
            @click="() => emit('confirm', true)"
          >
            <span class="text-gray-300">{{ $t('common.ok') }}</span>
          </AlertDialogAction>
        </div>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
