import { reactive } from 'vue';
import { ConfirmModal } from '@wision/ui';

export default defineNuxtPlugin((nuxtApp) => {
  const state = reactive({
    visible: false,
    title: '',
    message: '',
    resolve: null as null | ((value: boolean) => void),
  });

  const confirm = (title: string, message: string) => {
    state.title = title;
    state.message = message;
    state.visible = true;

    return new Promise<boolean>((resolve) => {
      state.resolve = resolve;
    });
  };

  const close = (result: boolean) => {
    state.visible = false;
    state.resolve?.(result);
    state.resolve = null;
  };

  const GlobalConfirmWrapper = defineComponent({
    name: 'GlobalConfirmWrapper',
    setup() {
      return () =>
        h(ConfirmModal, {
          show: state.visible,
          message: state.message,
          title: state.title,
          onConfirm: (value: boolean) => close(value)
        });
    },
  });

  nuxtApp.vueApp.component('GlobalConfirmModal', GlobalConfirmWrapper);

  return {
    provide: {
      confirm
    }
  };
});
