import useApiClient, { useRequestInit } from './apiClient';
import type { DashboardEntry } from '@user-api/dashboard/model';
import type { Widget } from '@user-api/widget/model';
import type { SelectedFilters } from '@device-api/filter/model';

type ClientDashboard = DashboardEntry & { id: string, filter: SelectedFilters };

export const useDashboardApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const cacheKeys = {
    dashboards: 'dashboards'
  };

  const getAllDashboards = async () => {
    const response = await apiClient<[DashboardEntry]>(
      `${baseUrl}/api/v1/dashboards`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    );

    const [dashboards, error] = response;
    const modified = (dashboards).map(dashboard =>
      ({ ...dashboard, id: dashboard.id }));

    return [modified, error] as [Array<ClientDashboard>, boolean];
  };

  const getDashboard = async (id: string) => {
    const [dashboard, error] = await apiClient<ClientDashboard>(
      `${baseUrl}/api/v1/dashboards/${id}`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    );

    return [{ ...dashboard, id: dashboard.id }, error] as [ClientDashboard, boolean];
  };

  const deleteDashboard = async (id: string) =>
    await apiClient<unknown>(
      `${baseUrl}/api/v1/dashboards/${id}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'text/plain',
        },
      }),
      false
    );

  const setDashboard = async (id: string, dashboard: unknown) =>
    await apiClient<unknown>(
      `${baseUrl}/api/v1/dashboards/${id}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(dashboard),
        headers: {
          Accept: 'text/plain',
        },
      }),
      false
    );

  const createDashboard = async (name: string, templateId?: string, useContext = false) => {
    const [dashboard, error] = await apiClient<DashboardEntry>(
      `${baseUrl}/api/v1/dashboards`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({ 
          name: name, 
          templateId: templateId ,
          useContext: useContext
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      })
    );

    return [dashboard, error] as [ClientDashboard, boolean];
  };

  const getTemplates = async () =>
    await apiClient<{ id: string, name: string }[]>(
      `${baseUrl}/api/v1/dashboards/template`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      })
    );

  const getWidgets = async () =>
    await apiClient<Widget[]>(
      `${baseUrl}/api/v1/widget`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'text/plain',
        },
      })
    );

  const updateName = async (name: string, id: string) => 
    await apiClient<Widget[]>(
      `${baseUrl}/api/v1/dashboards/${id}/updateName`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify({ name, id }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      }),
      false
    );

  const updateFilter = async (filter: SelectedFilters, id: string) => 
    await apiClient<void>(
      `${baseUrl}/api/v1/dashboards/${id}/filter`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(filter),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      }),
      false
    );

  return {
    cacheKeys,
    getall: getAllDashboards,
    set: setDashboard,
    get: getDashboard,
    delete: deleteDashboard,
    create: createDashboard,
    getWidgets: getWidgets,
    getTemplates,
    updateName,
    updateFilter
  };
};
